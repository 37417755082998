<template>
  <div class="moreData">
    <div class="clickMoreData" v-if="$store.state.loadIsaac">
      {{ $t('other.translate90') }}
    </div>
    <div
      class="clickMoreData"
      @click="clickMoreDataS"
      v-if="
        !$store.state.loadIsaac &&
          $props.pageNum * $props.pageSize < $props.total
      "
    >
    {{ $t('other.translate91') }}
    </div>
    <div
      class="clickMoreData"
      v-if="
        !$store.state.loadIsaac &&
          $props.pageNum * $props.pageSize >= $props.total
      "
    >
    {{ $t('other.translate92') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    pageNum: Number,
    pageSize: Number,
  },
  data() {
    return {};
  },
  mounted() {
    console.log("$store.state.loadIsaac ", this.$store.state.loadIsaac);
    console.log(
      "$props.pageNum * $props.pageSize ",
      this.$props.pageNum * this.$props.pageSize,
      this.$props.total
    );
  },
  methods: {
    clickMoreDataS() {
      let _this = this;
      this.$store.commit("setLodaIsaac", true);
      _this.$emit("clickMoreDataS");
    },
  },
};
</script>

<style scoped>
.clickMoreData {
  width: 100%;
  text-align: center;
  margin-top: 2vh;
  padding-bottom: 4vh;
  color: #bbb;
}
</style>
