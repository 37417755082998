import { render, staticRenderFns } from "./number.vue?vue&type=template&id=5c538ec3&scoped=true"
import script from "./number.vue?vue&type=script&lang=js"
export * from "./number.vue?vue&type=script&lang=js"
import style0 from "./number.vue?vue&type=style&index=0&id=5c538ec3&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c538ec3",
  null
  
)

export default component.exports