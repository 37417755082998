var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moreData"},[(_vm.$store.state.loadIsaac)?_c('div',{staticClass:"clickMoreData"},[_vm._v(" "+_vm._s(_vm.$t('other.translate90'))+" ")]):_vm._e(),(
      !_vm.$store.state.loadIsaac &&
        _vm.$props.pageNum * _vm.$props.pageSize < _vm.$props.total
    )?_c('div',{staticClass:"clickMoreData",on:{"click":_vm.clickMoreDataS}},[_vm._v(" "+_vm._s(_vm.$t('other.translate91'))+" ")]):_vm._e(),(
      !_vm.$store.state.loadIsaac &&
        _vm.$props.pageNum * _vm.$props.pageSize >= _vm.$props.total
    )?_c('div',{staticClass:"clickMoreData"},[_vm._v(" "+_vm._s(_vm.$t('other.translate92'))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }