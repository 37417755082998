<template>
  <div>
    <div class="tradeHeaderWrap">
      <div class="tradeHeader">
        <div class="thLeft flexSpindle">
          <i class="iconfont iconlist_right" @click="$store.state.app.drawer.show = true"></i>
          <span>{{ currentCoin.coin }}/{{ currentCoin.base }}</span>
          <div v-for="(item, index) in coins.USDT" :key="index" class="dis">
            <div class="greenBox" v-if="item.symbol == currentCoin.coin + '/' + currentCoin.base" :class="{
              redBox: currentCoin.change < 0,
              greenBox: currentCoin.change >= 0,
            }">
              {{ item.rose }}
            </div>
          </div>
        </div>
        <div class="thRight flexSpindle">
          <!-- <van-dropdown-menu>
            <van-dropdown-item v-model="exchangeTable" :options="option1" @change="exchangeCut" />
          </van-dropdown-menu> -->
          <!-- <i
            class="iconfont iconmarkets_"
            @click="$router.push({ name: 'tradeDetail' })"
          ></i> -->
          <div class="icoBox">
            <!-- <icons
              class="flexSpindle"
              name="more"
              @click.native="popMenu.show = true"
              type="ios"
            /> -->
            <div class="imgSD" style="width: 4.5802vw;height: 4.5802vw;" @click="
              $router.push({
                name: 'tradeDetail',
                params: { symbol: currentCoin.coin + '/' + currentCoin.base },
              })
              ">
              <img src="../../../assets/img/kxqh.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tradeBox">
      <div class="tradeLeft">
        <div class="trThead flexBothSidesVertical">
          <span>{{ $t("home.l2") }}</span><span>{{ $t("trade.number") }}</span>
        </div>
        <div :class="WTValue === 'limit' ? 'leftBox' : 'leftBoxH'" v-if="showMAIOrMAI != '0'">
          <div v-if="showMAIOrMAI == '1'">
            <div class="ZXT" v-for="(item, i) in plate.askRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:red">{{ formatPlatePrice(item.price) }}</span><span>{{ formatPlateAmount(item.amount)
                  }}</span>
              </div>
            </div>
          </div>
          <div v-if="showMAIOrMAI == '2'">
            <div class="ZXT" v-for="(item, i) in plate.bidRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:green">{{
                  formatPlatePrice(item.price)
                }}</span><span>{{ formatPlateAmount(item.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="WTValue === 'limit' ? 'leftBox' : 'leftBoxH'">
          <div class="leftBoxHTWO">
            <div class="ZXT" v-for="(item, i) in plate.askRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:red">{{ formatPlatePrice(item.price) }}</span><span>{{ formatPlateAmount(item.amount)
                  }}</span>
              </div>
            </div>
          </div>
          <div class="dis" :class="{
            'hqlb-text': currentCoin.change < 0,
            'hqlb-text-green': currentCoin.change >= 0,
          }">
            {{ priceAA | toFixed(baseCoinScale) }}
            <div class="asmallicon">
              <img src="../../../assets/img/icon_fall.png" alt="" v-if="currentCoin.change < 0" />
              <img src="../../../assets/img/icon_rise.png" alt="" v-if="currentCoin.change >= 0" />
            </div>
          </div>
          <div class="leftBoxHTWO">
            <div class="ZXT" v-for="(item, i) in plate.bidRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:green">{{
                  formatPlatePrice(item.price)
                }}</span><span>{{ formatPlateAmount(item.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="sd5 disB" style="margin-top: 5.3435vw;">
            <van-dropdown-menu>
              <van-dropdown-item v-model="plate.maxPostion" :options="SDOption" @change="SDChange" />
            </van-dropdown-menu>
            <div class="imgSD" @click="showPicker = true">
              <img src="../../../assets/img/sgfk.png" alt="" />
            </div>
          </div>
          <div class="zsjBox"
            style="border: .2545vw solid #000000;border-radius: 1.0178vw;margin-top: 2.5445vw;display: flex;align-items: center;">
            <span class="zsj">{{ $t('other.translate12') }}: </span>
            <van-field v-model="form.buy.stopProfitPrice" type="number" placeholder="0.00" />
          </div>
        </div>

      </div>
      <!-- 买卖盘 -->
      <div class="tradeRight">
        <div class="bc">
          <van-dropdown-menu>
            <van-dropdown-item v-model="WTValue" :options="WTOption" @change="chooseLimitOrMarket" />
          </van-dropdown-menu>
        </div>
        <div class="bc">
          <van-stepper v-model="plate.price" decimal-length="2" placeholder="价格" />
        </div>
        <div class="bc">
          <!-- <van-field v-model="value" type="number" placeholder="数量" /> -->
          <input v-model="form.buy.limitAmount" type="number" placeholder="数量" />
          <!-- <input
            v-else
            v-model="form.sell.marketAmount"
            type="number"
            placeholder="数量"
          /> -->
          <div class="textADA">
            {{ currentCoin.coin }}
          </div>
        </div>
        <div class="disBew">
          <span>{{ $t('other.translate7') }}</span>
          <span v-if="exchangeTable === 1">{{ contractAssetPNL.balance.toFixed(2) || 0
            }}USDT</span>
          <span v-else>{{ contractAssetPNL.balanceExperience || 0
            }}{{ currentCoin.coin }}</span>
        </div>
        <div class="tradeSlider">
          <div class="vslider">
            <van-slider bar-height="2.358px" v-model="percent"
              :active-color="$store.state.app.nightModel ? '#000' : '#333'" inactive-color="#e5e5e5"
              @input="sliderChange">
              <div slot="button" class="customButton">
                <span
                  style="background-color: #000;display: inline-block;width: 3.8168vw;height: 3.8168vw;display: flex;align-items: center;justify-content: center;">
                  <span style="background-color: #fff;display: inline-block;width: 2.2901vw;height: 2.2901vw;"></span>
                </span>
                <!--  {{ percent + '%' }} -->
                <!-- <icons name="menu" type="ios" /> -->
              </div>
            </van-slider>
          </div>
          <div class="vsliderNum" style="margin-bottom: 4.0712vw;">
            <span style="color: #000">0</span><span>{{ percent }}% </span>
          </div>
          <div class="tradeSliderDownI" :class="{ buying: tradeTypeBuy }">
            <i class="active"></i><i :class="{ active: percent >= 25 }"></i><i :class="{ active: percent >= 50 }"></i><i
              :class="{ active: percent >= 75 }"></i><i :class="{ active: percent >= 100 }"></i>
          </div>
          <div class="sliderNum">
            <span>0</span><span>25</span><span>50</span><span>75</span><span>100</span>
          </div>
        </div>
        <div class="trThead martop">
          <span>{{ $t('other.translate8') }} {{ Short || 0 }}USDT</span>
        </div>
        <div class="disBew2" @click="showPrice = true">
          <span>x {{ selectBuyLever }}</span>
          <van-icon name="arrow-down" />
          <!-- <div class="hh">
            <div :class="{
              activeBGC: selectBuyLever == item,
              bor1: selectBuyLever != item,
            }" @click="buyLever(item)" v-for="item in currentCoin.levers" :key="item">
              {{ item }}
            </div>
          </div> -->
        </div>
        <div class="pt3" style="margin-top: 3.0534vw;">
          <van-button size="large" class="tradeSubmitMore" type="info" @click="buyWithLimitPrice">{{
            $t('other.translate9') }}</van-button>
          <van-button size="large" class="tradeSubmitMore"
            style="margin-top: 2.0356vw;background-color: transparent;border: .2545vw solid #000000;color: #000"
            type="danger" @click="sellLimitPrice">{{ $t('other.translate10') }}</van-button>
        </div>

        <div class="zsjBox"
          style="width: 41.7303vw;border: .2545vw solid #000000;border-radius: 1.0178vw;display: flex;align-items: center;">
          <span class="zsj">{{ $t('other.translate11') }}:</span>
          <van-field v-model="form.buy.stopLossPrice" type="number" placeholder="0.00" />
        </div>
      </div>
    </div>
    <div class="btn" v-if="!islogin" @click="goToLogin">{{ $t('other.translate6') }}</div>
    <div class="tradeContent">
      <div class="tradeTitle flexBothSidesVertical">
        <span  @click="chooseTab(0)" class="dqcc">{{ $t('other.translate47') }}<span :class="{ 'dqccLine': chooseNum == 0 }" :lang="$i18n.locale"></span></span>
        <span @click="chooseTab(1)" class="dqcc">{{ $t('other.translate22') }}<span :class="{ 'dqccLine': chooseNum == 1 }"  :lang="$i18n.locale"></span></span>
        <span @click="clickMore()" style="font-size: 3.5623vw;color: #000">{{ $t('other.translate23') }} ></span>
      </div>
      <div class="tabsBox" v-if="chooseNum == 1">
        <div class="tradeHistory">
        <!-- <loading /> -->
        <!-- <nodata v-if="holdOrder.rows.length == 0"></nodata> -->
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          :description="$t('other.translate58')" v-if="currentOrder.rows.length == 0" />
        <ul class="assetsList" v-else>
          <li v-for="(item, idx) of currentOrder.rows" :key="idx" class="li">
            <div class="forTitle">
              <span>{{ item.symbol }}</span>
              <div class="forTitleBox">X{{ item.leverage }}</div>
              <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                {{ item.direction == "BUY" ? $t('other.translate9') : $t('other.translate10') }}
              </div>
            </div>
            <div class="time">
              {{ formatTimestamp(item.time) }}
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate24') }}</div>
              <div class="">{{ item.price }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate25') }}</div>
              <div class="">{{ item.stopProfitPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate26') }}</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate27') }}</div>
              <div class="">{{ item.currentPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate28') }}</div>
              <div class="">{{ item.stopLossPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate29') }}</div>
              <!-- 找不到字段是啥,枚举也不知道,后端也找不到 -->
              <div class="">{{ $t('other.translate79') }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate30') }}</div>
              <div class="">{{ item.marginMoney }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate31') }}</div>
              <div class="">{{ item.orderFee.toFixed(2) }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate32') }}</div>
              <div class="">{{ followTypeFormat(item) }}</div>
            </div>
            <div class="boxOne1">
              <div class="numALl" :class="item.profitLost >= 0 ? 'greenBg' : 'redBg'">
                {{
                  item.profitLost >= 0
                    ? item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                    : "-" + item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                }}
              </div>
            </div>
            <div class="boxOne">
              <div class="dis" :lang="$i18n.locale">
                <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain @click="close(item)">{{
                  $t('other.translate33') }}</van-button>
                <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                  @click="doModifyStop(item, tskb)">{{ $t('other.translate34') }}</van-button>
              </div>
            </div>
          </li>
        </ul>
        <moreData :total="paramsIsaacOne.total" :pageNum="paramsIsaacOne.pageNo" :pageSize="paramsIsaacOne.pageSize"
          @clickMoreDataS="clickMoreDataOne" />
      </div>
      </div>
      <div class="tabsBox" v-if="chooseNum == 0">
        <div class="tradeHistory">
        <!-- <loading /> -->
        <!-- <nodata v-if="holdOrder.rows.length == 0"></nodata> -->
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          :description="$t('other.translate58')" v-if="holdOrder.rows.length == 0" />
        <ul class="assetsList" v-else>
          <li v-for="(item, idx) of holdOrder.rows" :key="idx" class="li">
            <div class="forTitle">
              <span>{{ item.symbol }}</span>
              <div class="forTitleBox">X{{ item.leverage }}</div>
              <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                {{ item.direction == "BUY" ? $t('other.translate9') : $t('other.translate10') }}
              </div>
            </div>
            <div class="time">
              {{ formatTimestamp(item.time) }}
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate24') }}</div>
              <div class="">{{ item.price }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate25') }}</div>
              <div class="">{{ item.stopProfitPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate26') }}</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate27') }}</div>
              <div class="">{{ item.currentPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate28') }}</div>
              <div class="">{{ item.stopLossPrice }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate29') }}</div>
              <!-- 找不到字段是啥,枚举也不知道,后端也找不到 -->
              <div class="">{{ $t('other.translate79') }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate30') }}</div>
              <div class="">{{ item.marginMoney }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate31') }}</div>
              <div class="">{{ item.orderFee.toFixed(2) }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate32') }}</div>
              <div class="">{{ followTypeFormat(item) }}</div>
            </div>
            <div class="boxOne1">
              <div class="numALl" :class="item.profitLost >= 0 ? 'greenBg' : 'redBg'">
                {{
                  item.profitLost >= 0
                    ? item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                    : "-" + item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                }}
              </div>
            </div>
            <div class="boxOne">
              <div class="dis" :lang="$i18n.locale">
                <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain @click="close(item)">{{
                  $t('other.translate33') }}</van-button>
                <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                  @click="doModifyStop(item, tskb)">{{ $t('other.translate34') }}</van-button>
              </div>
            </div>
          </li>
        </ul>
        <moreData :total="paramsIsaacOne.total" :pageNum="paramsIsaacOne.pageNo" :pageSize="paramsIsaacOne.pageSize"
          @clickMoreDataS="clickMoreDataOne" />
      </div>
      </div>
    </div>
    <van-action-sheet v-model="popMenu.show" :actions="popMenu.actions" @select="onMenuSelect" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" :cancel-button-text="$t('other.translate118')"
        :confirm-button-text="$t('button.assets.sure')" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <van-popup v-model="showPrice" position="bottom">
      <div class="option" v-for="item in currentCoin.levers" @click="buyLever(item)">{{ item }}</div>
    </van-popup>
    <van-dialog v-model="showModifyStop" :title="$t('other.translate13')" :show-cancel-button="false"
      :showConfirmButton="false">
      <div style="margin-top: 24px;padding-left: 24px;display: flex;justify-content: space-around;align-items: center;">
        <span class="textNode">{{ $t('other.translate87') }}</span>
        <input class="inputs" :lang="$i18n.locale" v-model="form.modifyStop.stopProfitPrice" placeholder="0.0" />
      </div>
      <div style="margin-top: 24px;padding-left: 24px;display: flex;justify-content: space-around">
        <span class="textNode">{{ $t('other.translate88') }}</span>
        <input class="inputs" :lang="$i18n.locale" v-model="form.modifyStop.stopLossPrice" placeholder="0.0" />
      </div>

      <div class="btnBox">
        <div class="btnC" @click="showModifyStop = false">{{ $t('button.otc.cancel') }}</div>
        <div class="btnS" @click="modifyStopProfitLoss">{{ $t('button.otc.sure') }}</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import moreData from "../../../components/moreData/moreData";

import number from "@/views/modules/number.vue";
let SockJS = require("sockjs-client");
let Stomp = require("stompjs");
// import Datafeeds from '@js/charting_library/datafeed/bitrade.js';
import { Toast } from "vant";

import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      // trade: {
      //   rows: [],
      // },
      paramsIsaacOne: {},
      paramsIsaacTow: {},
      paramsIsaacThree: {},
      showModifyStop: false,
      selectBuyLever: "onea",
      contractAssetPNL: {
        dynamicProfit: 0.0,
        balance: 0.0,
        available: 0.0,
        frozon: 0.0,
        floatPnL: 0.0,
        dynamicProfitPercent: 0.0,
        riskRate: 0.0,
      },
      tskb: "tskb",
      wallet: {
        base: 0.0,
        coin: 0.0,
      },
      currentOrder: {
        rows: [],
      },
      historyOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      holdOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      closeOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      showPicker: false,
      exchangeTable: 1,
      columns: [this.$t('other.translate17'), this.$t('other.translate18'), this.$t('other.translate19')],
      limitTab: true,
      form: {
        buy: {
          limitPrice: 0.0,
          limitAmount: 0.0,
          marketAmount: 0.0,
          limitTurnover: 0.0,
          lever: 0,
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
          // stopPrice: 0.0,
          // stopBuyPrice: 0.0,
          // stopBuyAmount: 0.0,
          // stopTurnover: 0.0
        },
        sell: {
          limitPrice: 0.0,
          limitAmount: 0.0,
          marketAmount: 0.0,
          limitTurnover: 0.0,
          lever: 0,
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
          // stopPrice: 0.0,
          // stopBuyPrice: 0.0,
          // stopBuyAmount: 0.0,
          // stopTurnover: 0.0
        },
        modifyStop: {
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
        },

      },
      chooseNum: 0,
      showMAIOrMAI: 0,
      coins: [],
      currentCoin: {
        base: "",
        coin: "",
        symbol: "",
        levers: [],
      },
      baseCoinScale: 2,
      value9: 0,
      plate: {
        maxPostion: 5,
        askRows: [],
        bidRows: [],
        price: 0.0,
      },
      priceAA: 0.0,
      defaultPath: "btc_usdt",
      numberIndex: 1,
      tradeTypeBuy: true,
      submiting: false,
      popMenu: {
        show: false,
        actions: [
          {
            name: this.$t("assets.index.n1"),
            value: "deposit",
          },
          {
            name: this.$t("assets.index.n3"),
            value: "transfer",
          },
          {
            name: this.$t("trade.add") + "/" + this.$t("trade.cancelChoice"),
            value: "fav",
          },
        ],
      },
      percent: 0,
      forms: {
        // 表单
        total: 0,
        price: "",
        number: "",
      },
      den: 1, // 分母 最大买卖盘
      actualBase: 0,
      actualProduct: 0,
      c: null,
      lastTick: {}, // 记录上一次tick
      showAll: true,
      option1: [
        { text: "资金交易", value: 1 },
        { text: "体验金交易", value: 2 },
      ],
      WTValue: "limit",
      WTOption: [
        { text: this.$t('other.translate20'), value: "limit" },
        { text: this.$t('other.translate21'), value: "market" },
      ],
      SDOption: [
        { text: this.$t('other.translate14'), value: 5 },
        { text: this.$t('other.translate15'), value: 10 },
        { text: this.$t('other.translate16'), value: 15 },
      ],
      num: 0,
      showPrice: false,
      coins: {
        _map: [],
        USDT: []
      },
    };
  },
  components: {
    number,
    moreData,
  },
  watch: {
    // percent: function(cur, old) {
    //   let scale = Number(cur / 100);
    //   let number = Number(this.actualProduct) * scale;
    //   if (this.tradeTypeBuy) {
    //     // 如果是买
    //     // 折算可买数量
    //     if (this.forms.price) {
    //       number = Number(this.actualBase / this.forms.price) * scale;
    //     }
    //   }
    //   // 滚动到最左边 设置为最小值
    //   if (number < this.minNumber) {
    //     number = this.minNumber;
    //   }
    //   if (this.tradeTypeBuy) {
    //     number = this.actualBase > 0 ? number : "";
    //   } else {
    //     number = this.actualProduct > 0 ? number : "";
    //   }
    //   this.forms.number = this.$tdp.fmtZero(number, this.digit);
    // },
    app: {
      handler(newValue, oldValue) {
        if (newValue.drawer.toptype) {
          this.init(newValue.drawer.toptype);
        }
      },
      deep: true,
    },
    "$store.state.symbol": function (cur, old) {
      this.den = 0;
    },
    "$store.state.bidask": {
      handler: function (cur, old) {
        if (!cur) {
          this.calculation();
          this.$store.state.bidask = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["app"]), // 获取整个对象
    member: function () {
      return this.$store.getters.member;
    },
    Short() {
      var num = this.form.buy.limitAmount;
      var price = this.plate.price;
      var multiple = this.selectBuyLever;
      var floor = this.coinScale;
      console.log("this.selectBuyLever", this.selectBuyLever);
      return ((num * price) / multiple).toFixed(2);
    },
    islogin() {
      return this.$store.state.isLogin;
    },
    coin() {
      return this.$mts.getVs(this.$store.state.symbol, 0);
    },
    market() {
      return this.$mts.getVs(this.$store.state.symbol, 1);
    },
    // wallet() {
    //   // 当前交易货币钱包
    //   let w = this.$store.state.ws.capital;
    //   if (!w || this.$mts.isEmpty(w)) {
    //     return false;
    //   }
    //   return w;
    // },
    base() {
      // 基础货币
      let that = this;
      if (!that.wallet || !that.wallet[that.market]) {
        return "--";
      }
      let w = that.wallet[that.market];
      that.actualBase = w.usable;
      return that.$tdp.meter(w.usable, that.digit);
    },
    product() {
      // 商品货币
      let that = this;
      if (!that.wallet) {
        return "--";
      }
      let w = that.wallet[that.coin];
      that.actualProduct = w.usable;

      return that.$tdp.meter(w.usable, that.digit);
    },
    tick() {
      // 交易行情
      let that = this;
      let tick = that.$store.state.ws.tick;
      if (tick) {
        return tick;
      }
      return { close: 0 };
    },
    money() {
      return this.$tdp.money(this.forms.price, this.market);
    },
    closeMoney() {
      return this.$tdp.money(this.price, this.market);
    },
    current() {
      // 当前交易货币配置
      if (!this.market || !this.$store.state.coin) {
        return null;
      }
      return this.$store.state.coin[this.market];
    },
    trade() {
      if (!this.coin || !this.$store.state.coin) {
        return null;
      }
      return this.$store.state.coin[this.coin];
    },
    digit() {
      let token = this.current;
      if (token && token.digit) {
        return token.digit[0];
      }
      return 8;
    },
    step() {
      return Math.pow(0.1, this.digit);
    },
    maxNumber() {
      if (this.trade) {
        if (this.islogin) {
          // 登录状态
          if (this.tradeTypeBuy) {
            let base = this.actualBase;
            if (this.forms.price) {
              let max = this.$tdp.fmtZero(
                Number(base / this.forms.price),
                this.digit
              );

              return Number(max);
            } else {
              return base;
            }
          } else {
            return this.actualProduct;
          }
        }
        return this.trade.maxbuy;
      }

      return 0;
    },
    minNumber() {
      if (this.trade) {
        if (this.islogin && this.tradeTypeBuy) {
          return this.actualBase > 0 ? this.trade.minbuy : 0;
        }
        if (this.islogin && !this.tradeTypeBuy) {
          return this.actualProduct > 0 ? this.trade.minbuy : 0;
        }
        return this.trade.minbuy;
      }
      return 0;
    },
    total() {
      // 交易总额
      let price = this.forms.price;
      let number = this.forms.number;
      if (this.percent == 100) {
        return this.tradeTypeBuy
          ? this.actualBase
          : this.actualProduct * this.forms.price;
      }
      return this.$tdp.toThousands(
        this.$tdp.fmtZero(Number(price * number), this.digit)
      );
    },
    buy() {
      // 买盘数据
      let that = this;
      let list = [];
      let buys = that.$store.state.ws.buys;
      if (buys && buys[that.digit]) {
        list = buys[that.digit];
        // 获取最大值
        for (let idx = 0; idx < list.length; idx++) {
          if (that.den < Number(list[idx].total)) {
            that.den = list[idx].total;
          }
          // 设置初始默认值
          if (idx == 0 && !that.tradeTypeBuy && !that.forms.price) {
            that.forms.price = that.$tdp.fmtZero(list[idx].price);
          }
        }
      }
      return list;
    },
    sell() {
      // 卖盘数据

      let that = this;
      let list = [];
      let sells = that.$store.state.ws.sells;

      if (sells && sells[that.digit]) {
        list = sells[that.digit];
        // 获取最大值
        for (let idx = 0; idx < list.length; idx++) {
          if (that.den < Number(list[idx].total)) {
            that.den = list[idx].total;
          }
          if (
            idx == list.length - 1 &&
            that.tradeTypeBuy &&
            !that.forms.price
          ) {
            that.forms.price = that.$tdp.fmtZero(list[idx].price);
          }
        }
      }
      return list;
    },
    order() {
      // 委托订单
      let that = this;
      if (that.$store.state.ws.actives) {
        let list = that.$store.state.ws.actives.list;
        let total = that.$store.state.ws.actives.total;
        if (total >= list.length) {
          that.showAll = false;
        }
        return list;
      }
      return [];
    },
    nodata() {
      // 无数据
      if (this.order.length == 0 || !this.islogin) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // this.$mts.rates();
  },
  created() {
    this.$bus.$on("funcBus", (data) => {
      this.close(data);
    });
    this.$bus.$on("showModifyStop", (data) => {
      this.modifySelectOrder = data;
      this.form.modifyStop.stopProfitPrice = data.stopProfitPrice;
      this.form.modifyStop.stopLossPrice = data.stopLossPrice;
      this.modifyStopProfitLoss();
    });

    this.getdefaultSymbol().then((res) => {
      this.defaultPath = res;
      this.init();
      if (this.islogin) {
        this.getHoldOrder();
      }
    });
    this.getSymbol()
    // this.chushihua();
  },
  beforeDestroy() {
    clearInterval(this.c);
  },
  methods: {
    // getSymbol() {
    //   this.$http.post(this.host + '/market/symbol-thumb', {}).then((response) => {
    //     let resp = response.body;
    //     //先清空已有数据
    //     for (let i = 0; i < resp.length; i++) {
    //       let coin = resp[i];
    //       coin.base = resp[i].symbol.split('/')[1];
    //       this.coins[coin.base] = [];
    //       this.coins._map = [];
    //       this.coins.favor = [];
    //     }
    //     for (let i = 0; i < resp.length; i++) {
    //       let coin = resp[i];
    //       coin.price = resp[i].close = resp[i].close.toFixed(resp[i].baseCoinScale);
    //       coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
    //       coin.coin = resp[i].symbol.split('/')[0];
    //       coin.base = resp[i].symbol.split('/')[1];
    //       coin.href = (coin.coin + '_' + coin.base).toLowerCase();
    //       coin.isFavor = false;
    //       coin.isGreen = resp[i].chg > 0 ? true : false;
    //       this.coins._map[coin.symbol] = coin;
    //       this.coins[coin.base].push(coin);
    //       if (coin.symbol == this.currentCoin.symbol) {
    //         this.currentCoin = coin;
    //         this.plate.price = coin.price;
    //         this.form.buy.limitPrice = this.form.sell.limitPrice = coin.price;
    //
    //         this.getSymbolLeverage(this.currentCoin.symbol);
    //       }
    //     }
    //
    //     this.startWebsock();
    //   });
    // },
    choose(index) {
      this.num = index
    },
    chooseTab(index) {
      this.chooseNum = index
    },
    clickMoreDataOne() {
      this.paramsIsaacOne.pageNo += 1;
      if (this.islogin) {
        this.getHoldOrder();
      }
    },
    followTypeFormat(row) {
      if (row.followType == 0) {
        return this.$t('other.translate35');
      }
      if (row.followType == 1) {
        if (row.followUserName) {
          return this.$t('other.translate36') + "(" + row.followUserName + ")";
        } else {
          return this.$t('other.translate36');
        }
      }
      if (row.fundsType === "TRIAL") {
        return this.$t('other.translate37');;
      }
      return this.$t('other.translate35');;
    },
    getdefaultSymbol() {
      return this.$http
        .get(this.host + "/market/default/symbol")
        .then((res) => {
          const data = res.body;
          if (data.code == 0) {
            return new Promise((resolve, reject) => {
              resolve(data.data.web);
            }).catch((reject) => reject("BTC_USDT"));
          }
        });
    },
    changeListCheck(index) {
      this.numberIndex = index;
    },
    calculation() {
      let that = this;
      let list = [];

      that.den = 0;
      if (that.$store.state.bid) {
        list = that.sell;
      }
      if (that.$store.state.ask) {
        list = that.buy;
      }
      for (let idx = 0; idx < list.length; idx++) {
        if (that.den < Number(list[idx].total)) {
          that.den = list[idx].total;
        }
      }
      that.$store.state.ask = false;
      that.$store.state.bid = false;
    },
    vRange(item) {
      let already = item.number - item.surplus;
      return Number((already / item.number) * 100).toFixed(2) + "%";
    },
    placeholder(prefix, value) {
      return prefix + value;
    },
    // close(value) {
    //   // 最新价格
    //   if (value) {
    //     return this.$tdp.fmtZero(this.$tdp.toThousands(value), this.digit);
    //   }
    //   return "--";
    // },
    vClass(n) {
      let last = this.$tdp.fmtZero(this.lastTick.close); // 历史数据
      let now = this.$tdp.fmtZero(n);
      if (!last || last == now) {
        return "";
      }
      return last > now ? "sellcolor" : "buycolor";
    },
    business() {
      // 开始下单
      let that = this;
      if (that.submiting) return; //防止多次提交
      that.submiting = true;
      that.$mts.posts({
        url: "order/business",
        data: {
          price: that.forms.price,
          num: that.forms.number,
          total: that.forms.total,
          type: that.tradeTypeBuy ? "BUY" : "SELL",
          coinPair: that.$route.params.symbol,
        },
        success(response) {
          that.submiting = false;
          if (response.data.code == 401) {
            that.$store.state.user.auth.show = true; //需要输入资金密码
          } else if (response.data.code == 200) {
            that.$toast(that.$t("validate.trade.coin.orderSuc"));
          }
        },
      });
    },
    revoke(item) {
      let that = this;
      if (that.submiting) return;
      that.submiting = true;
      that.$mts.posts({
        url: "order/revoke",
        data: {
          id: item.id,
          type: item.type,
          symbol: that.$route.params.symbol,
        },
        success(response) {
          that.submiting = false;
          if (response.data.code == 401) {
            that.$store.state.user.auth.show = true; //需要输入资金密码
          } else if (response.data.code == 200) {
            that.$toast(that.$t("validate.trade.coin.withdrawalSuc"));
          }
        },
      });
    },
    itemClick(item) {
      if (item) {
        this.forms.price = this.$tdp.fmtZero(item.price, 4);
      }
    },
    onMenuSelect(item) {
      switch (item.value) {
        case "deposit":
          this.$router.push({
            name: "deposit",
          });
          break;
        case "transfer":
          this.$router.push({
            name: "transfer",
          });
          break;
        case "fav":
          if (this.$store.state.isLogin) {
            const that = this;
            that.$mts.post({
              url: "user/optional",
              data: {
                symbol: that.coin + "_" + that.market,
              },
              success(response) {
                if (response.data.code == 200) {
                  that.$mts.favor(() => {
                    that.$toast(that.$t("validate.success"));
                  });
                } else {
                  // 失败
                  that.$toast(response.data.message);
                }
              },
            });
          } else {
            // 去登陆
            this.$router.push({
              name: "signin",
              query: {
                path: "trade/" + that.coin + "_" + that.market,
              },
            });
          }
          break;
      }
    },
    changeType(type) {
      this.tradeTypeBuy = type;
      if (type && this.sell.length > 0) {
        this.forms.price = this.$tdp.fmtZero(
          this.sell[this.sell.length - 1].price,
          this.digit
        );
      } else if (!type && this.buy.length > 0) {
        this.forms.price = this.$tdp.fmtZero(this.buy[0].price, this.digit);
      } else {
        this.forms.price = "";
      }
      this.forms.number = "";
    },
    clickMore() {
      console.log("this.paramsIsaacOne", this.paramsIsaacOne);
      if (!this.paramsIsaacOne.symbol || !this.paramsIsaacOne.pageSize) {
        return;
      }
      this.$store.commit("setContractAssetPNLAll", this.contractAssetPNL);
      this.$store.commit("setCurrentCoinAll", this.currentCoin);
      // this.$store.commit("setHoldOrderAll", this.holdOrder);
      // this.$store.commit("setCurrentOrderAll", this.currentOrder);
      // this.$store.commit("setCloseOrderAll", this.closeOrder);
      this.$store.commit("setParamsIsaacOne", this.paramsIsaacOne);
      this.$store.commit("setParamsIsaacTow", this.paramsIsaacTow);
      this.$store.commit("setParamsIsaacThree", this.paramsIsaacThree);

      // this.$router.push({
      //   name: "allorders",
      // });
      this.$mts.goto("allorders");
    },
    getCNYRate() {
      this.$http
        .post(this.host + "/market/exchange-rate/usd-cny")
        .then((response) => {
          let resp = response.body;
          this.CNYRate = resp.data;
        });
    },
    getSymbolScale() {
      //获取精度
      this.$http
        .post(this.host + this.api.market.symbolInfo, {
          symbol: this.currentCoin.symbol,
        })
        .then((response) => {
          let resp = response.body;
          if (resp != null) {
            this.currentCoin.coinScale = resp.coinScale;
            this.currentCoin.baseCoinScale = resp.baseCoinScale;
            this.baseCoinScale = resp.baseCoinScale;
            this.coinScale = resp.coinScale;

            this.symbolFee = resp.fee;
          }
        });
    },
    SDChange() {
      this.init();
    },
    init(selectSymbol) {
      if (this.refreshTimer) {
          //如果定时器在运行则关闭
          clearInterval(this.refreshTimer);
      }
      var params = this.$route.params[0];
      //console.log(params);
      //  let params = this.$route.params.pathMatch;
      if (params == undefined) {
        if (selectSymbol) {
          params = selectSymbol.replace(/\//, "_");
        }
      }
      if (params == undefined) {
        // this.$router.push("/exchange/" + this.defaultPath);
        params = this.defaultPath;
      }
      const basecion = params.split("_")[1];
      if (basecion) {
        this.basecion = basecion.toLowerCase();
      }
      let coin = params.toUpperCase().split("_")[0];
      let base = params.toUpperCase().split("_")[1];
      this.currentCoin.symbol = coin + "/" + base;
      this.currentCoin.coin = coin;
      this.currentCoin.base = base;

      this.paramsIsaacOne.pageNo = 1;
      this.paramsIsaacOne.pageSize = 10;
      this.paramsIsaacOne.symbol = this.currentCoin.symbol;
      this.holdOrder.rows = [];

      this.getCNYRate();
      this.getSymbolScale();
      this.getSymbol(); //包含 K线图、getFavor、startWebsock等
      this.getPlate(); //买卖盘
      // this.getTrade();
      // console.log("this.isLogin", this.$store.state.isLogin);
      // if (this.isLogin) {
      if (this.islogin) {
        this.getWallet(); //账户资产信息
        this.getCurrentOrder(); //当前委托
        this.getCloseOrder(); //历史委托
        this.getHoldOrder();
        this.getContractAssetPNL();
        this.getMoney();
        var that = this;
        this.refreshTimer = setInterval(that.refreshAccount, 30000);
      }
      this.sliderBuyLimitPercent = 0;
      this.sliderSellLimitPercent = 0;
      this.sliderBuyMarketPercent = 0;
      this.sliderSellMarketPercent = 0;
      this.sliderBuyStopPercent = 0;
      this.sliderSellStopPercent = 0;
    },
    getTrade() {
      let params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 16;
      this.$http
        .post(this.host + this.api.market.trade, params)
        .then((response) => {
          this.trade.rows = [];
          let resp = response.body;
          for (let i = 0; i < resp.length; i++) {
            this.trade.rows.push(resp[i]);
          }
        });
    },
    getCoin(symbol) {
      return this.coins._map[symbol];
    },
    startWebsock() {
      // this.loadingButton7 = true;
      if (this.stompClient) {
        this.stompClient.ws.close();
      }
      let stompClient = null;
      let that = this;
      let socket = new SockJS(that.host + that.api.market.ws);
      stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      stompClient.debug = false;
      // this.datafeed = new Datafeeds.WebsockFeed(that.host+'/market',this.currentCoin,stompClient);
      // this.getKline();
      stompClient.connect({}, function (frame) {
        // that.datafeed = new Datafeeds.WebsockFeed(
        //   that.host + "/market",
        //   that.currentCoin,
        //   stompClient,
        //   that.baseCoinScale
        // );
        // that.getKline();
        //订阅价格变化消息
        stompClient.subscribe("/topic/market/thumb", function (msg) {
          let resp = JSON.parse(msg.body);
          let coin = that.getCoin(resp.symbol);
          if (coin != null) {
            // coin.price = resp.close.toFixed(2);
            //当前coin就是 currentCoin
            // coin.price = resp.close;

            //这个改成从盘口的最新价格来取 注释掉 coin.price   coin.close
            if (!coin.price || coin.price <= 0) {
              coin.price = resp.close.toFixed(resp.baseCoinScale);
            }
            coin.rose =
              resp.chg > 0
                ? "+" + (resp.chg * 100).toFixed(2) + "%"
                : (resp.chg * 100).toFixed(2) + "%";
            // coin.close = resp.close.toFixed(2);
            // coin.high = resp.high.toFixed(2);
            // coin.low = resp.low.toFixed(2);
            if (!coin.close || coin.close <= 0) {
              coin.close = resp.close;
            }
            coin.high = resp.high;
            coin.low = resp.low;
            coin.turnover = parseInt(resp.volume);
            coin.volume = resp.volume;
            coin.usdRate = resp.usdRate;
          }
        });
        //订阅实时成交信息
        stompClient.subscribe(
          "/topic/market/trade/" + that.currentCoin.symbol,
          function (msg) {
            let resp = JSON.parse(msg.body);
            if (resp.length > 0) {
              for (let i = 0; i < resp.length; i++) {
                that.trade.rows.unshift(resp[i]);
              }
            }
            if (that.trade.rows.length > 16) {
              that.trade.rows = that.trade.rows.slice(0, 16);
            }
          }
        );
        if (that.islogin) {
          //订阅委托取消信息
          stompClient.subscribe(
            "/topic/market/order-canceled/" +
            that.currentCoin.symbol +
            "/" +
            that.member.id,
            function (msg) {
              let resp = JSON.parse(msg.body);
              that.refreshAccount();
            }
          );
          //订阅委托交易完成
          stompClient.subscribe(
            "/topic/market/order-completed/" +
            that.currentCoin.symbol +
            "/" +
            that.member.id,
            function (msg) {
              let resp = JSON.parse(msg.body);
              that.refreshAccount();
            }
          );
          //订阅委托部分交易
          stompClient.subscribe(
            "/topic/market/order-trade/" +
            that.currentCoin.symbol +
            "/" +
            that.member.id,
            function (msg) {
              let resp = JSON.parse(msg.body);
              that.refreshAccount();
            }
          );
          //订阅持仓状态消息
          stompClient.subscribe(
            "/topic/market/forceclose/" + that.member.id,
            function (msg) {
              let resp = JSON.parse(msg.body);
              that.refreshAccount();
            }
          );
          //订阅持仓状态消息
          stompClient.subscribe(
            "/topic/market/pnl-change/" +
            that.currentCoin.symbol +
            "/" +
            that.member.id,
            function (msg) {
              let resp = JSON.parse(msg.body);
              // throttle(that.refreshAccount());
            }
          );
        }

        //订阅盘口消息
        stompClient.subscribe(
          "/topic/market/trade-plate/" + that.currentCoin.symbol,
          function (msg) {
            let resp = JSON.parse(msg.body);

            that.plate.price = resp.price;
            that.priceAA = resp.price;

            let coin = that.getCoin(that.currentCoin.symbol);
            coin.close = that.plate.price;
            coin.price = that.plate.price.toFixed(
              that.currentCoin.baseCoinScale
            );
            {
              let sellTradePlate = resp.sellTradePlate;
              // if (resp.direction == "SELL") {
              let asks = sellTradePlate.items;
              that.plate.askRows = [];
              let totle = 0;
              for (let i = that.plate.maxPostion - 1; i >= 0; i--) {
                let ask = {};
                if (i < asks.length) {
                  ask = asks[i];
                } else {
                  ask["price"] = 0;
                  ask["amount"] = 0;
                }
                ask.direction = "SELL";
                ask.position = i + 1;
                that.plate.askRows.push(ask);
              }
              for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
                if (
                  i == that.plate.askRows.length - 1 ||
                  that.plate.askRows[i].price == 0
                ) {
                  that.plate.askRows[i].totalAmount =
                    that.plate.askRows[i].amount;
                } else {
                  that.plate.askRows[i].totalAmount =
                    that.plate.askRows[i + 1].totalAmount +
                    that.plate.askRows[i].amount;
                }
                totle += that.plate.askRows[i].amount;
              }
              that.plate.askTotle = totle;
            }

            {
              let buyTradePlate = resp.buyTradePlate;
              let bids = buyTradePlate.items;
              that.plate.bidRows = [];
              let totle = 0;
              for (let i = 0; i < that.plate.maxPostion; i++) {
                let bid = {};
                if (i < bids.length) {
                  bid = bids[i];
                } else {
                  bid["price"] = 0;
                  bid["amount"] = 0;
                }
                bid.direction = "BUY";
                bid.position = i + 1;
                that.plate.bidRows.push(bid);
              }
              for (let i = 0; i < that.plate.bidRows.length; i++) {
                if (i == 0 || that.plate.bidRows[i].amount == 0) {
                  that.plate.bidRows[i].totalAmount =
                    that.plate.bidRows[i].amount;
                } else {
                  that.plate.bidRows[i].totalAmount =
                    that.plate.bidRows[i - 1].totalAmount +
                    that.plate.bidRows[i].amount;
                }
                totle += that.plate.bidRows[i].amount;
              }
              that.plate.bidTotle = totle;
            }
          }
        );
      });
    },
    computePercent(row) {
      var width = 0;
      var totle =
        row.direction === "BUY" ? this.plate.bidTotle : this.plate.askTotle;
      if (row.totalAmount) {
        width = (row.totalAmount / totle).toFixed(4) * 100 + "%";
      }
      return width;
    },
    formatPlatePrice(price) {
      if (price == 0) {
        return "--";
      } else {
        return price.toFixed(this.baseCoinScale);
      }
    },
    buyLever(val) {
      this.selectBuyLever = val;
      this.form.buy.lever = val;
      this.showPrice = false
    },
    formatPlateAmount(amount) {
      if (amount == 0) {
        return "--";
      } else {
        return amount.toFixed(this.coinScale);
      }
    },
    getSymbol() {
      this.$http
        .post(this.host + this.api.market.thumb, {})
        .then((response) => {
          let resp = response.body;
          //先清空已有数据
          for (let i = 0; i < resp.length; i++) {
            let coin = resp[i];
            coin.base = resp[i].symbol.split("/")[1];
            this.coins[coin.base] = [];
            this.coins._map = [];
            this.coins.favor = [];
          }
          for (let i = 0; i < resp.length; i++) {
            let coin = resp[i];
            coin.price = resp[i].close = resp[i].close.toFixed(
              resp[i].baseCoinScale
            );
            coin.rose =
              resp[i].chg > 0
                ? "+" + (resp[i].chg * 100).toFixed(2) + "%"
                : (resp[i].chg * 100).toFixed(2) + "%";
            coin.coin = resp[i].symbol.split("/")[0];
            coin.base = resp[i].symbol.split("/")[1];
            coin.href = (coin.coin + "_" + coin.base).toLowerCase();
            coin.isFavor = false;
            coin.isGreen = resp[i].chg > 0 ? true : false;
            this.coins._map[coin.symbol] = coin;
            this.coins[coin.base].push(coin);
            // console.log(
            //   "this.coins[coin.base];????????",
            //   this.coins[coin.base]
            // );
            this.$store.state.coinsList = this.coins[coin.base];
            if (coin.symbol == this.currentCoin.symbol) {
              this.currentCoin = coin;
              this.plate.price = coin.price;
              this.priceAA = coin.price;

              this.form.buy.limitPrice = this.form.sell.limitPrice = coin.price;

              this.getSymbolLeverage(this.currentCoin.symbol);
            }
          }

          this.startWebsock();
        });
    },
    exchangeCut(type) {
      this.exchangeTable = type;
    },
    getSymbolLeverage(symbol) {
      let param = { symbol: symbol };
      //获取杠杆
      this.$http
        .post(this.host + this.api.exchange.getLeverage, param)
        .then((response) => {
          let result = response.body;
          if (result.code == 0 && result.data.length > 0) {
            this.selectBuyLever = result.data[0];
            this.selectSellLever = result.data[0];
            this.form.buy.lever = this.selectBuyLever;
            this.form.sell.lever = this.selectSellLever;
            this.currentCoin.levers = result.data;
          }
        });
    },
    chooseLimitOrMarket(val) {
      if (val == "market") {
        this.limitTab = false;
      } else {
        this.limitTab = true;
      }
    },
    getPlate() {
      //买卖盘
      let params = {};
      params["symbol"] = this.currentCoin.symbol;
      this.$http
        .post(this.host + this.api.market.platemini, params)
        .then((response) => {
          this.plate.askRows = [];
          this.plate.bidRows = [];
          let resp = response.body;

          if (resp.ask && resp.ask.items) {
            for (let i = 0; i < resp.ask.items.length; i++) {
              if (i == 0) {
                resp.ask.items[i].totalAmount = resp.ask.items[i].amount || 0;
              } else {
                resp.ask.items[i].totalAmount =
                  (resp.ask.items[i - 1].totalAmount || 0) +
                  (resp.ask.items[i].amount || 0);
              }
            }
            if (resp.ask.items.length >= this.plate.maxPostion) {
              for (let i = this.plate.maxPostion; i > 0; i--) {
                let ask = resp.ask.items[i - 1];
                ask.direction = "SELL";
                ask.position = i;
                this.plate.askRows.push(ask);
              }
              const rows = this.plate.askRows,
                len = rows.length,
                totle = rows[0].totalAmount || 0;
              this.plate.askTotle = totle;
            } else {
              for (let i = 5; i > resp.ask.items.length; i--) {
                let ask = { price: 0, amount: 0 };
                ask.direction = "SELL";
                ask.position = i;
                ask.totalAmount = ask.amount || 0;
                this.plate.askRows.push(ask);
              }
              for (let i = resp.ask.items.length; i > 0; i--) {
                let ask = resp.ask.items[i - 1];
                ask.direction = "SELL";
                ask.position = i;
                this.plate.askRows.push(ask);
              }
              const rows = this.plate.askRows,
                len = rows.length,
                totle = rows[this.plate.maxPostion - resp.ask.items.length]
                  ? rows[this.plate.maxPostion - resp.ask.items.length]
                    .totalAmount
                  : 0 || 0;
              this.plate.askTotle = totle;
            }
          }
          if (resp.bid && resp.bid.items) {
            for (let i = 0; i < resp.bid.items.length; i++) {
              if (i == 0)
                resp.bid.items[i].totalAmount = resp.bid.items[i].amount;
              else
                resp.bid.items[i].totalAmount =
                  resp.bid.items[i - 1].totalAmount + resp.bid.items[i].amount;
            }
            for (let i = 0; i < resp.bid.items.length; i++) {
              let bid = resp.bid.items[i];
              bid.direction = "BUY";
              bid.position = i + 1;
              this.plate.bidRows.push(bid);
              if (i == this.plate.maxPostion - 1) break;
            }
            if (resp.bid.items.length < this.plate.maxPostion) {
              for (
                let i = resp.bid.items.length;
                i < this.plate.maxPostion;
                i++
              ) {
                let bid = { price: 0, amount: 0 };
                bid.direction = "BUY";
                bid.position = i + 1;
                bid.totalAmount = 0;
                this.plate.bidRows.push(bid);
              }
              const rows = this.plate.bidRows,
                len = rows.length,
                totle = rows[resp.bid.items.length - 1]
                  ? rows[resp.bid.items.length - 1].totalAmount
                  : 0;
              // if(rows[resp.bid.items.length - 1]!=undefined){
              //     const totle = rows[resp.bid.items.length - 1].totalAmount;
              // }else{
              //     let totle;
              // }
              this.plate.bidTotle = totle;
            } else {
              const rows = this.plate.bidRows,
                len = rows.length,
                totle = rows[len - 1].totalAmount;
              this.plate.bidTotle = totle;
            }
            //this.plate.bidRows = this.plate.bidRows.slice(0,this.plate.maxPostion);
          }
        });
    },
    //限价买入
    buyWithLimitPrice() {
      // console.log("现价买入拉", this.exchangeTable);
      let params = {};
      if (this.limitTab) {
        if (this.form.buy.limitAmount == "") {
          // this.$Message.error(this.$t("exchange.buyamounttip"));
          Toast.fail(this.$t('other.translate125'));
          return;
        }
        // let maxAmount = this.wallet.base / this.form.buy.limitPrice;
        // if (this.form.buy.limitAmount > maxAmount) {
        //     this.$Message.error({
        //         title: this.$t("exchange.tip"),
        //         desc:
        //             this.$t("exchange.buyamounttipwarning") + this.toFloor(maxAmount)
        //     });
        //     return;
        // }
        params["type"] = "LIMIT_PRICE";
        params["amount"] = this.form.buy.limitAmount;
      }
      else{
        if (this.form.buy.limitAmount == "") {
          // this.$Message.error(this.$t("exchange.buyamounttip"));
          Toast.fail(this.$t('other.translate125'));
          return;
        }
        params["type"] = "MARKET_PRICE";
        params["amount"] = this.form.buy.limitAmount;
      }
      // else {
      //   if (this.form.buy.marketAmount == "") {
      //     // this.$Message.error(this.$t("exchange.pricetip"));
      //     Toast.fail("请输入交易额");
      //     return;
      //   }
      //   // if (this.form.buy.marketAmount > parseFloat(this.wallet.base)) {
      //   //     this.$Message.error({
      //   //         title: this.$t("exchange.tip"),
      //   //         desc: this.$t("exchange.pricetipwarning") + this.wallet.base
      //   //     });
      //   //     return;
      //   // }
      //   params["type"] = "MARKET_PRICE";
      //   params["amount"] = this.form.buy.marketAmount;
      // }
      let that = this;

      params["symbol"] = this.currentCoin.symbol;
      params["price"] = this.form.buy.limitPrice;

      params["direction"] = "BUY";

      params["leverage"] = this.form.buy.lever;
      params["stopProfitPrice"] = this.form.buy.stopProfitPrice;
      params["stopLossPrice"] = this.form.buy.stopLossPrice;

      // String period, //1 分钟、5 分钟、15 分钟、30 分钟、1 小时
      //     String coinId

      params["period"] = "1min";
      params["coinId"] = "USDT";
      if (this.exchangeTable === 1) {
        params["fundsType"] = "1";
      } else {
        params["fundsType"] = "0";
      }

      // params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      this.loadingButton1 = true;
      this.$http
        .post(this.host + this.api.exchange.orderAdd, params)

        .then((response) => {
          let resp = response.body;
          if (resp.code == 0) {
            // this.$Message.success(that.$t("exchange.success"));
            Toast.success("提交成功");
            this.loadingButton1 = false;
            this.form.buy.limitAmount = 0;
            this.form.buy.marketAmount = 0;
            this.form.buy.stopProfitPrice = 0;
            this.form.buy.stopLossPrice = 0;
            this.refreshAccount();
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);
            this.loadingButton1 = false;
          }
        });
    },

    //限价卖出
    sellLimitPrice() {
      let params = {};
      if (this.limitTab) {
        if (this.form.buy.limitAmount == "") {
          // this.$Message.error(this.$t("exchange.sellamounttip"));
          Toast.fail(this.$t('other.translate124'));
          return;
        }
        params["type"] = "LIMIT_PRICE";
        params["amount"] = this.form.buy.limitAmount;
        // if (this.form.sell.limitPrice == "") {
        //     this.$Message.error({
        //         title: this.$t("exchange.tip"),
        //         desc: this.$t("exchange.sellpricetip")
        //     });
        //     return;
        // }
        // if (this.form.sell.limitAmount > parseFloat(this.wallet.base)) {
        //     this.$Message.error({
        //         title: this.$t("exchange.tip"),
        //         desc: "最多能卖" + this.wallet.base + "个"
        //         // desc: this.$t("exchange.sellamounttipwarning") + this.wallet.base
        //     });
        //     return;
        // }
      } else {
        if (this.form.buy.limitAmount == "") {
          // this.$Message.error(this.$t("exchange.sellamounttip"));
          Toast.fail(this.$t('other.translate124'));
          return;
        }
        params["type"] = "MARKET_PRICE";
        params["amount"] = this.form.buy.limitAmount;
      }
      // else {
      //   if (this.form.sell.marketAmount == "") {
      //     // this.$Message.error(this.$t("exchange.sellamounttip"));
      //     Toast.fail("请输入卖出量");
      //     return;
      //   }
      //   params["type"] = "MARKET_PRICE";
      //   params["amount"] = this.form.sell.marketAmount;
      // }
      params["symbol"] = this.currentCoin.symbol;
      params["price"] = this.form.sell.limitPrice;

      params["direction"] = "SELL";

      params["leverage"] = this.form.sell.lever;
      params["stopProfitPrice"] = this.form.buy.stopProfitPrice;
      params["stopLossPrice"] = this.form.buy.stopLossPrice;
      if (this.exchangeTable === 1) {
        params["fundsType"] = "1";
      } else {
        params["fundsType"] = "0";
      }
      // params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      let that = this;
      this.loadingButton4 = true;
      this.$http
        .post(this.host + this.api.exchange.orderAdd, params)
        .then((response) => {
          let resp = response.body;

          if (resp.code == 0) {
            // this.$Message.success(that.$t("exchange.success"));
            Toast.success(this.$t('other.translate123'));

            this.loadingButton4 = false;
            this.form.buy.limitAmount = 0;
            this.form.sell.marketAmount = 0;
            this.form.buy.stopProfitPrice = 0;
            this.form.buy.stopLossPrice = 0;
            this.refreshAccount();
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);

            this.loadingButton4 = false;
          }
        });
    },
    refreshAccount() {
      if (this.islogin) {
        this.getCurrentOrder();
        this.getCloseOrder();
        this.getWallet();
        this.getHoldOrder();
      }

      this.getContractAssetPNL();
      this.getMoney();
    },
    getMoney() {
      this.$http.post(this.host + "/uc/asset/wallet").then((response) => {
        var resp = response.body;
        if (resp.code == 0) {
          this.tableMoney = resp.data;
          console.log("合约余额???????", this.tableMoney);
          this.contractAssetPNL.balance = this.tableMoney[0].balance;
          this.contractAssetPNL.balanceExperience = this.tableMoney[0].trialfundsBalance;
        } else {
          // this.$Message.error(this.loginmsg);
          Toast.fail(this.loginmsg);
        }
      });
    },
    getWallet() {
      this.$http
        .post(this.host + this.api.uc.wallet + this.currentCoin.base, {})
        .then((response) => {
          let resp = response.body;
          this.wallet.base = resp.data.balance || "";
        });
      // this.$http
      //     .post(this.host + this.api.uc.wallet + this.currentCoin.base, {})
      //     .then(response => {
      //         let resp = response.body;
      //         this.wallet.base = (resp.data && resp.data.balance) || '';
      //     });
    },
    getContractAssetPNL() {
      this.$http
        .post(this.host + this.api.uc.contractAssetPNL, {})
        .then((response) => {
          let resp = response.body;
          if (resp.code == 0) {
            this.contractAssetPNL.available = resp.data.available;
            this.contractAssetPNL.frozon = resp.data.frozon;
            this.contractAssetPNL.floatPnL = resp.data.floatPnL;
            this.contractAssetPNL.dynamicProfitPercent =
              resp.data.dynamicProfitPercent;
            this.contractAssetPNL.dynamicProfit = resp.data.dynamicProfit;
            this.contractAssetPNL.riskRate = resp.data.riskRate;
          } else {
            // this.$Message.error({
            //     title: this.$t("exchange.tip"),
            //     desc: resp.message
            // });
          }
        });
    },
    getHoldOrder(pageNo) {
      //查询历史委托
      this.paramsIsaacOne.holdStatus = "HOLD";

      let that = this;
      this.$http
        .post(this.host + this.api.exchange.hold, this.paramsIsaacOne)
        .then((response) => {
          let resp = response.body;

          this.paramsIsaacOne = {
            ...this.paramsIsaacOne,
            total: resp.totalElements,
          };

          if (resp.content != undefined) {
            if (resp.content.length > 0) {
              this.holdOrder.rows = [];
              this.holdOrder.rows.push(...resp.content);
              this.$store.commit("setLodaIsaac", false);
            } else {
              this.holdOrder.rows = [];
            }
          } else {
            this.holdOrder.rows = [];
          }
        });
    },
    getCurrentOrder() {
      //查询当前委托
      let params = {};
      params["pageNo"] = 1;
      params["pageSize"] = 10;
      params["symbol"] = this.currentCoin.symbol;
      let that = this;
      this.currentLoading = true;
      this.$store.state.loadIsaac = false;

      this.paramsIsaacTow = params;
      this.$http
        // .post(this.host + this.api.binaryOption.personalCurrent, params)
        .post(this.host + this.api.exchange.current, params)
        .then((response) => {
          let resp = response.body;

          this.paramsIsaacTow = {
            ...this.paramsIsaacTow,
            total: resp.totalElements,
          };

          if (resp.content && resp.content.length > 0) {
            this.currentOrder.rows = [];
            this.currentOrder.rows = resp.content.slice(0, 10);
            this.currentOrder.rows.forEach((row, index) => {
              row.skin = that.skin;
              row.price = row.type == "MARKET_PRICE" ? "市价" : row.price;
            });
          } else if (resp.content && resp.content.length == 0) {
            this.currentOrder.rows = [];
          }
          this.currentLoading = false;
        });
    },
    getCloseOrder(pageNo) {
      //查询历史委托
      // if (pageNo == undefined) {
      //     pageNo = this.historyOrder.page;
      // } else {
      //     pageNo = pageNo - 1;
      // }
      // this.closeOrder.rows = []; //清空数据
      let params = {};
      params["pageNo"] = 1;
      params["pageSize"] = this.closeOrder.pageSize;
      params["symbol"] = this.currentCoin.symbol;
      let that = this;
      this.closeListLoading = true;
      this.$store.state.loadIsaac = false;

      this.paramsIsaacThree = params;
      this.$http
        .post(this.host + this.api.exchange.closeList, params)
        .then((response) => {
          let resp = response.body;
          let rows = [];

          this.paramsIsaacThree = {
            ...this.paramsIsaacThree,
            total: resp.totalElements,
          };

          if (resp.content != undefined) {
            if (resp.content.length > 0) {
              this.closeOrder.total = resp.totalElements;
              this.closeOrder.page = resp.number;
              for (let i = 0; i < 10; i++) {
                let row = resp.content[i];
                if (row) {
                  row.skin = that.skin;
                  // row.price =
                  //     row.type == "MARKET_PRICE"
                  //         ? that.$t("exchange.marketprice")
                  //         : row.price;
                  // this.historyOrder.rows.push(row);
                  rows.push(row);
                }
              }
              this.closeOrder.rows = rows;
            }
            this.closeListLoading = false;
          }
        });
    },
    onConfirm(value, index) {
      this.showPicker = false;
      this.showMAIOrMAI = index;
    },
    sliderChange(value) {
      if (this.WTValue === "limit") {
        this.form.buy.limitAmount = (
          (this.contractAssetPNL.balance / this.plate.price) *
          (value / 100)
        ).toFixed(2);
      } else {
        this.form.buy.limitAmount = (
          this.contractAssetPNL.balance *
          (value / 100)
        ).toFixed(2);
      }
    },
    exchangeCut(type) {
      this.exchangeTable = type;
    },
    goToLogin() {
      // 去登陆
      this.$router.push({
        name: "signin",
        // query: {
        //   path: "secondContract/" + that.coin + "_" + that.market,
        // },
      });
    },
    close(row) {
      let order = row; //this.holdOrder.rows[index];
      // this.$Modal.confirm({
      //     content: "是否平仓",
      //     onOk: () => {
      this.$http
        .post(
          this.host + this.api.exchange.orderClose + "/" + order.orderId,
          {}
        )
        .then((response) => {
          let resp = response.body;
          if (resp.code == 0) {
            this.refreshAccount();
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);
          }
        });
      //     }
      // });
    },
    doModifyStop(order, val) {
      window.addEventListener("scroll", this.move);
      this.modifySelectOrder = order;
      // if (val == "tska") {
      //   this.showModifyStop = false;
      //   this.modifyStopProfitLoss(this.modifySelectOrder);
      // } else {
      this.form.modifyStop.stopProfitPrice = order.stopProfitPrice;
      this.form.modifyStop.stopLossPrice = order.stopLossPrice;
      this.showModifyStop = true;
      // }
    },
    modifyStopProfitLoss(order) {
      this.showModifyStop = false;

      let param = {
        orderId: this.modifySelectOrder.orderId,
        stopProfitPrice: this.form.modifyStop.stopProfitPrice,
        stopLossPrice: this.form.modifyStop.stopLossPrice,
      };
      //获取杠杆
      // console.log("订单", this.modifySelectOrder);
      this.$http
        .post(this.host + this.api.exchange.modifyStopProfitLoss, param)
        .then((response) => {
          let result = response.body;
          if (result.code == 0) {
            this.$Message.success("修改成功");
          }
        });
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp); // 注意时间戳通常是秒为单位，需要乘以1000转为毫秒
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() 是从0开始的
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      console.log("year", year);
      return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
        } ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`;
    },
  },
  destroyed: function () {
    console.log("i left！");
    if (this.refreshTimer) {
      //如果定时器在运行则关闭
      clearInterval(this.refreshTimer);
    }
    if (window.subscibeObjectLine) {
      for (let key in window.subscibeObjectLine) {
        window.subscibeObjectLine[key].unsubscribe();
      }
    }
    window.subscibeObjectLine = [];
    if (window.subscibeObjectTrade) {
      for (let key in window.subscibeObjectTrade) {
        clearInterval(window.subscibeObjectTrade[key]);
      }
      window.subscibeObjectTrade = [];
    }
  },
};
</script>
<style scoped>
.textNode {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  display: inline-block;
}

.btnBox {
  width: 54.9618vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11.4504vw;
}

.btnC {
  height: 9.1603vw;
  width: 25.4453vw;
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 600;
  line-height: 9.1603vw;
  text-align: center;
  color: #00000080;
  border: .2545vw solid #00000080;
  border-radius: 1.0178vw
}

.btnS {
  height: 9.1603vw;
  width: 25.4453vw;
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 600;
  line-height: 9.1603vw;
  text-align: center;
  background: #0052FF;
  color: #fff;
  border-radius: 1.0178vw
}

::v-deep .van-dialog {
  width: 84.9873vw;
  height: 71.7557vw;
  border-radius: 4.0712vw;
}

::v-deep .van-field__label {
  padding-top: 1.7812vw !important
}

::v-deep .van-dialog__footer {
  border: none !important;
  margin-top: 17.5573vw
}

.inputs:lang(zh-CN) {
  height: 9.1603vw !important;
  border: .2545vw solid #0000001A !important;
  border-radius: 1.0178vw !important;
  padding-left: 8px;
  width: 246px;
}

.inputs:lang(en-US) {
  height: 9.1603vw !important;
  border: .2545vw solid #0000001A !important;
  border-radius: 1.0178vw !important;
  padding-left: 8px;
  width: 200px;
}

::v-deep .van-dialog .van-dialog__footer .van-button {
  border: none !important;
}

::v-deep .van-dialog__header {
  font-family: Inter;
  font-size: 4.5802vw;
  font-weight: 600;
}

.option {
  width: 100%;
  height: 12.7226vw;
  text-align: center;
  line-height: 12.7226vw;
}

.time {
  width: 100%;
  font-family: Inter;
  font-size: 3.0534vw;
  font-weight: 400;
  color: #00000080;
  margin-top: -6.1069vw;
  display: flex;
  justify-content: flex-start;
}

.forTitleBox {
  width: auto;
  min-width: 5.8524vw;
  height: 4.0712vw;
  font-size: 3.0534vw;
  text-align: center;
  line-height: 4.0712vw;
  border-radius: 2.5445vw;
  color: #fff;
  padding: 0 1.2723vw;
  margin-left: 2.0356vw;
  background: linear-gradient(114.88deg, #000000 42.57%, #7C7C7C 94.06%);
}

.btn {
  background-color: #000;
  border: none;
  height: 8.1425vw;
  border-radius: 1.0178vw;
  width: 91.6031vw;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  line-height: 8.1425vw;
}

.tradeSubmitMore {
  width: 41.7303vw;
  height: 8.1425vw;
  background-color: #000000;
  border: none;
  border-radius: 1.0178vw;
  font-family: Inter;
  font-size: 4.0712vw;
  font-weight: 500;

}

::v-deep .van-dropdown-menu {
  height: 7.3791vw !important;
}

::v-deep .van-stepper {
  height: 7.3791vw !important;
}

::v-deep .van-stepper__input {
  height: 7.3791vw !important;
  background-color: transparent !important;
  border: none;
}

::v-deep .van-field__control {
  height: 2.5445vw;
  width: 38.1679vw;
  border: none;
  margin-top: .5089vw;
}

::v-deep .van-dropdown-menu__title {
  margin-top: 1.2723vw;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.3079vw;
}

.tagBox {
  width: 20.3562vw;
  height: 8.1425vw;
  background-color: #F6F6F6;
  border-radius: 1.0178vw;
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 600;
  line-height: 8.1425vw;
  text-align: center;
  color: #808080;

}

.active {
  border: .2545vw solid #000000;
  color: #000000;
}

.greenBox {
  font-size: 10.1048px;
  width: 37.728px;
  margin-left: 11.79px;
  background: rgba(22, 199, 132, 0.2);
  color: rgba(22, 199, 132, 0.5);
}

.redBox {
  font-size: 10.1048px;
  width: 37.728px;
  margin-left: 11.79px;
  background: rgba(234, 57, 67, 0.2);
  color: #ea3943;
}

.tradeLeftL {
  width: 86.46px;
}

.tradeLeftr {
  width: 86.46px;
}

.trThead {
  padding-right: 15.72px;
}

.trThead span {
  font-size: 11.5279px;
  white-space: nowrap;
  color: #000;
}

.leftBox {
  height: 33vh;
  overflow-y: auto;
}

.leftBoxH {
  height: 29vh;
  overflow-y: auto;
}

.ZXT {
  padding: 3.93px 0;
  /* background: green; */
}

.leftBoxHTWO {
  height: 15vh;
  overflow-y: auto;
}

.van-dropdown-menu__title .van-ellipsis {
  font-size: 11.79px;
  font-family: SF Pro;
  font-weight: 900;
}

.van-dropdown-menu__title:after {
  top: 1.4vh;
  right: 14.148px;
}

.van-dropdown-menu__title {
  background: #eff0f2;
  /* width: 110.04px; */
  width: 141.48px;
  /* padding: 0.4vh; */
}

.icoBox {
  margin-left: 7.86px;
}

.van-stepper {
  display: flex;
  flex-wrap: nowrap;
  height: 3vh;
}

.tradeRight {
  overflow: hidden;
  padding-right: 7.86px;
}

.bc .van-stepper .van-stepper__input {
  height: 3vh;
  margin: 0;
  border: none;
  background-color: transparent;
}

.bc {
  background: #eff0f2;
  margin-bottom: 2.0356vw;
  display: flex;
  align-items: center;
}

.van-dropdown-menu,
.van-dropdown-menu__bar {
  background: #eff0f2;
  height: 3vh;
  box-shadow: none;
}

.zsjBox .van-field {
  height: 3vh;
  line-height: 3vh;
  padding: 0px !important;
  width: 77%;
}

.zsjBox .van-cell .van-cell__value .van-field__body {
  height: 1vh;
  line-height: 3vh;
}

.zsjBox .van-cell .van-cell__value .van-field__body input[type="text"] {
  margin: 0;
  background: transparent;
  border: none !important;
}

input[type="number"] {
  margin: 0;
  background: #eff0f2;
  height: 3vh;
  border: none !important;
}

.textADA {
  color: #616e85;
  font-size: 11.79px;
  font-family: SF Pro;
  font-weight: 900;
  padding-right: 7.86px;
}

.disBew {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-weight: 510;
  font-size: 3.0534vw;
  font-family: Inter;
}

.disBew2 {
  width: 41.7303vw;
  height: 7.3791vw;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2723vw;

}

.hh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #616e85;
  font-weight: 510;
  font-size: 11.79px;
  font-family: SF Pro;
  margin-top: 7.86px;
  width: 353.7px;
}

.tradeSlider .sliderNum {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  top: 19.65px;
  left: 0;
  right: 0;
}

.martop {
  margin-top: 1vh;
}

.martop2 {
  margin-top: 0.4vh;
}

.van-button--large {
  height: 4vh;
  line-height: 4vh;
  color: #fff;
}

.bor1 {
  width: 39.3px;
  height: 3vh;
  line-height: 3vh;
  text-align: center;
  border: .393px solid #1789fa;
  color: #1789fa;
  border-radius: 1.0178vw;
}

.activeBGC {
  width: 39.3px;
  height: 3vh;
  line-height: 3vh;
  text-align: center;
  border: .393px solid #1789fa;
  border-radius: 1.0178vw;
  background: #1789fa;
  color: #fff;
}

/* .zsjBox {
  width: 100%;
  border: .393px solid #1789fa;
  margin-top: 0.8vh;
  padding: 3.144px 7.86px;
  color: #616e85;
  font-weight: 510;
  font-family: SF Pro;
  font-size: 11.79px;
  box-sizing: border-box;
} */

.zsj {
  color: #0d1421;
  width: 12.7226vw;
}

.dis:lang(zh-CN) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.dis:lang(en-US) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: -18.7226vw
}

.disB {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.sd5 {
  margin-top: 0.4vh;
  width: 100%;
  color: #616e85;
  font-weight: 510;
  font-family: SF Pro;
  font-size: 11.79px;
  box-sizing: border-box;
}

.imgSD {
  width: 8.1425vw;
  height: 8.1425vw;
}

img {
  width: 100%;
  height: 100%;
}

.dqcc {
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 500;
  line-height: 4.3104vw;
  text-align: center;
  color: #000 !important;
  position: relative;
}

.dqccLine:lang(zh-CN) {
  position: absolute;
  background: #000;
  width: 10.1781vw;
  height: .7634vw;
  margin-top: 3.93px;
  border-radius: 1.3099px;
  top: 2vh;
  left: 3.144px;
}

.dqccLin:lang(en-US) {
  position: absolute;
  background: #000;
  width: 10.1781vw;
  height: .7634vw;
  margin-top: 3.93px;
  border-radius: 1.3099px;
  top: 2vh;
  left: 54.234px;
}

.tradeSlider .vslider .van-slider {
  z-index: 1;
}

.hqlb-text {
  font-size: 13.362px;
  color: #f72a26;
  font-weight: bold;
}

.hqlb-text-green {
  font-size: 13.362px;
  color: green;
  opacity: 0.5;
  font-weight: bold;
}

.asmallicon {
  margin-left: 7.86px;
  width: 3.93px;
  height: 1.6vh;
}

.login {
  width: 100%;
  padding: 0 39.3px;
}

.li {
  width: 87.2774vw;
  border-radius: 4.0712vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  border: .2545vw solid #0000001A;
  margin-bottom: 2.0356vw;
}

.boxOne {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne1 {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne div:first-child {
  font-family: Inter;
  font-size: 3.0534vw;
  font-weight: 500;
  text-align: left;
  color: #00000080
}

.boxOne div:last-child {
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 600;
  text-align: left;
  color: #000
}

.forTitle {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 2vh;
  font-family: Inter;
  font-size: 4.0712vw;
  font-weight: 600;

}

.type {
  width: 20.3562vw;
  height: 7.888vw;
  background-color: #000;
  border-radius: 1.0178vw;
  margin-right: 15.72px;
  color: #fff;
  font-family: Inter;
  font-size: 3.5623vw;
  font-weight: 600;
  line-height: 7.888vw;
  text-align: center;
  margin-left: 21.374vw
}


.leftBox::-webkit-scrollbar,
.leftBox::-webkit-scrollbar-horizontal,
.leftBox::-webkit-scrollbar-thumb {
  display: none;
}

.leftBoxH::-webkit-scrollbar,
.leftBoxH::-webkit-scrollbar-horizontal,
.leftBoxH::-webkit-scrollbar-thumb {
  display: none;
}

.leftBoxHTWO::-webkit-scrollbar,
.leftBoxHTWO::-webkit-scrollbar-horizontal,
.leftBoxHTWO::-webkit-scrollbar-thumb {
  display: none;
}

.numALl {
  width: 78.6px;
  height: 3vh;
  /* background: #16c784; */
  color: #fff;
  text-align: center;
  line-height: 3vh;
  border-radius: 3.8168vw;
}

.xgButton:lang(zh-CN) {
  width: 19.084vw;
  height: 7.6336vw;
  font-size: 11.79px;
  margin-right: 7.86px;
  background: #0000001A;
  border-radius: 1.0178vw;
  border: none;

}

.xgButton:lang(en-US) {
  width: auto;
  height: 7.6336vw;
  font-size: 11.79px;
  margin-right: 7.86px;
  background: #0000001A;
  border-radius: 1.0178vw;
  border: none;
  padding: 0 5px !important;

}

.disPadd {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19.65px; */
}

.redBg {
  background: #FA4940;
  color: #fff;
  text-align: center;
}

.greenBg {
  background: #3AD29F;
  color: #fff;
  text-align: center;
}
</style>
